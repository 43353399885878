import React from 'react';
import './Why.css';

const Why = () => {
    const qualities =[
        {
            title: "Free Cancellation",
            desc: "If your plans undergo changes, rest assured that you have the flexibility to cancel your reservation without incurring any charges. This option is available to you up to 24 hours before the scheduled pick-up time, providing ample time to adjust your arrangements as needed."
        },
        {
            title: "Professional Service",
            desc: "With a management team of over twenty years travel expertise and a dedicated team of experienced drivers all professionally trained, fully vetted and CRB checked we promise to provide you the best service available catering for all your specific demands."
        },
        {
            title: "Low Cost Transfers",
            desc: "Our taxi services prioritize affordability while ensuring safe and convenient transportation for our customers. With transparent pricing and efficient routes, we strive to make travel accessible to all without sacrificing comfort or convenience."
        },
    ]
  return (
    <div className='container why'>
      <h2>Why Us</h2>
      <p>Choose us for unparalleled reliability, exceptional service, and unbeatable value in transportation solutions.</p>
      <div className='why-us'>
        {qualities.map(quality=>(
            <div className='why-box'>
                <h3>{quality.title}</h3>
                <p className='why-desc'>{quality.desc}</p>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Why
