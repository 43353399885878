import React from 'react';
import './City.css';

const City = () => {
    const areas = [
        {
            img: "images/city.jpg",
            name: "City1"
        },
        {
            img: "images/city.jpg",
            name: "City2"
        },
        {
            img: "images/city.jpg",
            name: "City3"
        },
        {
            img: "images/city.jpg",
            name: "City4"
        },
        {
            img: "images/city.jpg",
            name: "City5"
        },
        {
            img: "images/city.jpg",
            name: "City6"
        },
        {
            img: "images/city.jpg",
            name: "City7"
        },
        {
            img: "images/city.jpg",
            name: "City8"
        },
    ]
  return (
    <div className='container areas'>
      <h2 className='area-title'>Areas Where We Provide Services</h2>
        <div className='areas-grid'>
            {areas.map(area=>(
                <div className='area-box'>
                    <img src={area.img} alt=''/>
                    <h3>{area.name}</h3>
                </div>
            ))}
        </div>
    </div>
  )
}

export default City
