import React from 'react';
import "./stripe.css";
import { BiError } from "react-icons/bi";

const Error = () => {
  return (
    <div className='container strip-pages'>
      <h1>Error!</h1>
      <p>Payment unsuccessful. Please check your <br/>payment details and try again.</p>
      <BiError className='check-icon'/>
      <h2>Enter details again</h2>
      <p>If you have any problem while filling <br/>the details please contact at <a  href = "mailto: contact@swifttransfers.co.uk">contact@swifttransfers.co.uk</a></p>
    </div>
  )
}

export default Error
