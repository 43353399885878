import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';



const About = () => {
  return (
    <div>
      <div className='container back about'>
        <h1>About Us</h1>
      </div>
      <div className='about-us container'>
        <div className='about-img'>
          <img src='images/cab.png' alt='' />
        </div>
        <div className='about-detail'>
          <h2>What Derive Us</h2>
          <p>At Swift Transfers, we're proud to be Luton's well-known airport taxi service. Our reputation is built on professionalism, transparency, and guaranteed satisfaction. We’re capable of serving any travel transport needs, whether you're traveling alone, or with your family, or as part of a larger group.</p>
          <h3>Our Drivers</h3>
          <p>With a management team of over twenty years travel expertise and a dedicated team of experienced drivers all professionally trained, fully vetted and CRB checked we promise to provide you the best service available catering for all your specific demands and requirements. we understand that quality Swift Transfers service requires more than just a professional appearance. They know the roadways in and around Luton, and are able to easily transport customers to Gatwick, Stansted, Heathrow and London City airports on time. We also serve all other areas, so even if you don't require airport taxi service, we can fill your transport needs.</p>
          <Link to='/contact-us'><button className='btn-one'>Contact Us</button></Link>
        </div>
      </div>
      <div className='container acordion'>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Parking charges
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='acc-panel'>
              <ul>
                <li>No congestion fees or charges</li>
                <li>Fixed rates for all destinations, no matter where you're bound</li>
                <li>Free child/baby seat on demand</li>
                <li>Flexibility in case your flight is rescheduled or cancelled</li>
                <li>In addition to those guarantees, we will match the rate of any competitor with a cheaper airport taxi service quote, and provide you with an additional 2 off your rate. It truly is that simple and easy. We guarantee it.</li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Understanding Our Services
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='acc-panel'>
              <p>We provide our clients with one of the widest range of services in the industry, including:</p><br />
              <ul>
                <li>Door to airport and airport to door service</li>
                <li>Pre-book taxi service</li>
                <li>Guaranteed pricing from pre-booking</li>
                <li>24-hour call centre for help and assistance</li>
                <li>Pick up and drop off at your desired address<br /><br /></li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Airports We Serve
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='acc-panel'>
              <p>We provide travel ease and peace of mind to customers traveling to any of the Transportation hubs within reach of Luton, including:</p><br/>
              <ul>
                <li>Gatwick Airport</li>
                <li>London City Airport</li>
                <li>Heathrow Airport</li>
                <li>Stansted Airport</li>
                <li>We're also happy to arrange transportation to other destinations throughout the area and even the across the country. No matter where you're bound, Swift Transfers can provide professional, affordable, timely assistance.</li>
              </ul>
            </AccordionItemPanel >
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
              Pre-Book Taxi Service with Us
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className='acc-panel'>
              <p>Swift Transfers offers one of the simplest pre-booking methods available. Three simple steps secure your travel method, price and travel date. You simply need to enter your pick-up or drop off location, choose the type of car you require for your travels, and then provide us with a few pieces of personal information. We'll guarantee your driver will be waiting for you where and when you choose, and we also guarantee the price quoted during your pre-booking.</p><br/>
              <p>We are dedicated to being your airport taxi service of choice. Whether you’re traveling locally, nationally or internationally, we have the professional drivers, fixed rates and quality service you deserve. Contact us today or use our online pre-booking form to secure your transportation</p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className='container'>
        <div className='about-bottom'>
          <h2>Need Any Help, Feel Free to Contact Us</h2>
        </div>
      </div>
    </div>
  )
}

export default About
