import React from 'react';
import "./stripe.css";
import { FaCheck } from "react-icons/fa";

const Thankyou = () => {
  return (
    <div className='container strip-pages'>
      <h1>Thank You!</h1>
      <p>Thank you for visiting Swift Transfers. You will <br/>recieve an Email message shortly.</p>
      <FaCheck className='check-icon'/>
      <h2>Check your Email</h2>
      <p>If you didn't recieve any mail <br/>please contact at <a  href = "mailto: contact@swifttransfers.co.uk">contact@swifttransfers.co.uk</a></p>
    </div>
  )
}

export default Thankyou
