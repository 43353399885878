import React from 'react';
import './Classes.css';
import {FaCarSide} from "react-icons/fa6";
import { Link } from 'react-scroll';


const Classes = () => {
    const carDetails =[
        {
          title: "Econom",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscingut labore et dolore magna aliqua.",
          price: "1.5", 
        },
        {
            title: "Standard",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscingut labore et dolore magna aliqua.",
            price: "2.5", 
        },
        {
            title: "Business",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscingut labore et dolore magna aliqua.",
            price: "4.0", 
        },
        {
            title: "SUV",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscingut labore et dolore magna aliqua.",
            price: "5.5", 
        },
    ]
  return (
    <div className='container classes'>
      <h2 className='classes-heading'>Cabs Classes and Rates</h2>
      <div className='cars-class'>
        {carDetails.map(detail=>(
            <div className='class-box'>
                <FaCarSide className='car-icon'/>
                <h3>{detail.title}</h3>
                <p>{detail.desc}</p>
                <div className='price'>$ {detail.price} / km</div>
                <Link to='calc' spy={true} smooth={true} offset={-100} duration={500}><button className='btn-one'>Book Now</button></Link>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Classes
