import React, { useState } from 'react';
import './Contact.css';
import { IoLocationSharp } from 'react-icons/io5';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';
import { FaGlobe } from 'react-icons/fa';

const Contact = () => {

  const [cformData, setcFormData] = useState({
    userName: "",
    userEmail: "",
    subject: "",
    message: "",
  });

  const [buttonText, setButtonText] = useState('Submit');

  const sendData = async (e) => {
    e.preventDefault()

    const res = await fetch("https://server.swifttransfers.co.uk/sharedata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }, body: JSON.stringify({
        cformData
      })
    });

    const data = await res.json();
    if (data.status === 401 || cformData.userName=="" || cformData.userEmail=="" || cformData.subject=="" || cformData.message=="") {
      console.log("error")
      alert("Please fill in all fields.")
    } else {
      console.log("Email Sent")
      setButtonText(buttonText === 'Submit' ? 'submitted successfully' : 'Submit');
      
      setTimeout(function () {
        setButtonText("Submit");
      }, 1500);

      setcFormData({ ...cformData, userName: "", userEmail: "", subject: "", message: "" });
    }

  }

  return (
    <div>
      <div className='container back contact'>
        <h1>Contact Us</h1>
      </div>
      <div className='container contact-form'>
        <form className='form'>
          <div className='form-two'>
            <sapn className="half">
              <label for="">Full Name</label>
              <input placeholder='Name' name='userName' value={cformData.userName} onChange={(event) => setcFormData({ ...cformData, userName: event.target.value })} />
            </sapn>
            <sapn className="half">
              <label for="">Email Address</label>
              <input placeholder='Email' name='emailAddress' value={cformData.userEmail} onChange={(event) => setcFormData({ ...cformData, userEmail: event.target.value })} />
            </sapn>
          </div>

          <div className="full">
            <label for="">Suject</label>
            <input placeholder='Enter Subject' name='subject' value={cformData.subject} onChange={(event) => setcFormData({ ...cformData, subject: event.target.value })} />
          </div>

          <div className="full">
            <label for="">Your Message</label>
            <textarea placeholder='Message' name='message' value={cformData.message} onChange={(event) => setcFormData({ ...cformData, message: event.target.value })} />
          </div>
          <button className='btn-one' onClick={sendData}>{buttonText}</button>
        </form>
      </div>

      <div className='container c-details'>
        <div className='c-box'>
          <IoLocationSharp className='c-icon' />
          <p>Norman Road Luton Bedfordshire LU31JN</p>
        </div>
        <div className='c-box'>
          <BsFillTelephoneFill className='c-icon' />
          <p>+447395342406</p>
        </div>
        <div className='c-box'>
          <HiMail className='c-icon' />
          <p>contact@swifttransfers.co.uk</p>
        </div>
        <div className='c-box'>
          <FaGlobe className='c-icon' />
          <p>swifttransfers.co.uk</p>
        </div>
      </div>
    </div>
  )
}

export default Contact
