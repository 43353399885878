import React, { useState } from 'react';

const Two = ({ formData, setFormData, distance, duration}) => {
  // console.log(formData.no_of_passengers)

  const passangers =[
    {value: 1}, 
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
    {value: 6}
]

const luggages = [
    {value: 0},
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4}
]

const hours = [
    {value: 0},
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
    {value: 6},
    {value: 7},
    {value: 8},
    {value: 9},
    {value: 10},
    {value: 11},
    {value: 12},
    {value: 13},
    {value: 14},
    {value: 15},
    {value: 16},
    {value: 17},
    {value: 18},
    {value: 19},
    {value: 20},
    {value: 21},
    {value: 22},
    {value: 23},
]

const minutes =[
    {value: 0},
    {value: 5},
    {value: 10},
    {value: 15},
    {value: 20},
    {value: 25},
    {value: 30},
    {value: 35},
    {value: 40},
    {value: 45},
    {value: 50},
    {value: 55},
]

const bags = [
    {value: 0},
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
    {value: 6},
    {value: 7},
    {value: 8},
]

const waitHours =[
    {value: 0},
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
    {value: 6},
]

const waitMins = [
    {value: 0},
    {value: 5},
    {value: 10},
    {value: 15},
    {value: 20},
    {value: 25},
    {value: 30},
    {value: 35},
    {value: 40},
    {value: 45},
    {value: 50},
    {value: 55},
]

const returnhours = [
    {value: 0},
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 4},
    {value: 5},
    {value: 6},
    {value: 7},
    {value: 8},
    {value: 9},
    {value: 10},
    {value: 11},
    {value: 12},
    {value: 13},
    {value: 14},
    {value: 15},
    {value: 16},
    {value: 17},
    {value: 18},
    {value: 19},
    {value: 20},
    {value: 21},
    {value: 22},
    {value: 23},
]

const returnminutes =[
    {value: 0},
    {value: 5},
    {value: 10},
    {value: 15},
    {value: 20},
    {value: 25},
    {value: 30},
    {value: 35},
    {value: 40},
    {value: 45},
    {value: 50},
    {value: 55},
]

// add return or not

const [model, setModel] = useState(false)


const closeReturn = () => {
    setModel(false);
}

const openToggle = ()=>{
  setModel(true); 
}

// if(document.querySelector('input[name="path"]').value==="Yes"){
//     setModel(true);
// }

// disable previus date

function restrictDate(){
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByClassName("pikup-date")[0].setAttribute('min', today);
}

function restrictReturn(){
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByClassName("return-date")[0].setAttribute('min', today);
}

// control passengers and lugage

let a = (2*(11 -(parseFloat(formData.no_of_passengers) + parseFloat(formData.Luggages))))-1;
let b = 11- (parseFloat(formData.no_of_passengers) + (parseFloat(formData.Hand_bags)/2));
let c = 10 - (parseFloat(formData.Luggages) + (parseFloat(formData.Hand_bags)/2));

  return (
    <div className='calc-main-box'>
      <div className='calc-left-box'>
        <div className='calc-title'>Details</div>
        <div className='calc-data details-box'>
          <div className='text-box'>
            <span className='colored-text'>Pik Up: </span>
            {formData.Pik_up}
          </div>
          <div className='text-box'>
            <span className='colored-text'>Drop Off: </span>
            {formData.Drop_off}
          </div>
          <hr className='hr-line'/>
          <div className='text-box'>
            <span className='colored-text'>Distance: </span>
            {(parseFloat(distance)*0.621371).toFixed(2)} mi
            {/* {formData.Total_Distance} */}
          </div>
          <div className='text-box'>
            <span className='colored-text'>Time: </span>
            {duration}
            {/* {formData.Total_Duration} */}
          </div>
          <hr className='hr-line'/>
          {/* <p className='tag-line'>Our Cabs Commit to Make Your Trips Unique</p> */}
        </div>
      </div>
      <div className='options calc-right-box'>
                <div className='calc-title'>Options</div>
                <div className='calc-data'>
                    <div className='data-line'>
                        <div className='data-inner'>
                            <div className='upper-first'>
                                
                                <input type='radio' name='path' value='No'
                                  onClick={closeReturn}
                                  onChange={(event) => setFormData({ ...formData, Add_return: event.target.value })}
                                  checked = {formData.Add_return === 'No'? true: false}/>
                                <label> One Way</label><br /><br />
                                
                                <input type='radio' name='path' value='Yes' id='open-return'
                                    onClick={openToggle}
                                  onChange={(event) => setFormData({ ...formData, Add_return: event.target.value })}
                                  checked = {formData.Add_return === 'Yes'? true : false}/>
                                <label> Return</label>
                        
                            </div>
                            <div className='upper-sec'>
                                <label>Passengers</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, no_of_passengers: event.target.value })} value={formData.no_of_passengers}>
                                    {passangers.slice(0, c).map((passanger)=>{
                                        return(
                                            <option>{passanger.value}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='upper-sec'>
                                <label>Luggage</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, Luggages: event.target.value })} value={formData.Luggages}>
                                {luggages.slice(0, b).map((luggage)=>{
                                        return(
                                            <option>{luggage.value}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='data-inner'>
                        <div className='b-second'>
                                <label>Hand Bags</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, Hand_bags: event.target.value })} value={formData.Hand_bags}>
                                {bags.slice(0, a).map((bag)=>{
                                        return(
                                            <option>{bag.value}</option>
                                        )
                                })}
                                </select>
                            </div>
                            <div className='b-second'>
                                <label>Waiting Time</label>
                                <div className='w-time'>
                                    <select className='full-w' onChange={(event) => setFormData({ ...formData, Wait_hour: event.target.value })} value={formData.Wait_hour}>
                                    {waitHours.map((waitHour)=>{
                                        return(
                                            <option>{waitHour.value}</option>
                                        )
                                     })}
                                    </select>
                                    <select className='full-w' onChange={(event) => setFormData({ ...formData, Wait_mint: event.target.value })} value={formData.Wait_mint}>
                                    {waitMins.map((waitMin)=>{
                                        return(
                                            <option>{waitMin.value}</option>
                                        )
                                     })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='data-line'>
                        <div className='data-inner'>
                        <div className='date'>
                                <label>Pick up date</label>
                                <input type='date' className='full-w pikup-date' onClick={restrictDate} onChange={(event) => setFormData({ ...formData, Pik_up_date: event.target.value })} value={formData.Pik_up_date}/>
                            </div>
                            <div className='time'>
                                <label >Hour</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, Pik_up_hour: event.target.value })} value={formData.Pik_up_hour}>
                                {hours.map((hour)=>{
                                        return(
                                            <option>{hour.value}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='time'>
                                <label>Min</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, Pik_up_min: event.target.value })} value={formData.Pik_up_min}>
                                {minutes.map((minute)=>{
                                        return(
                                            <option>{minute.value}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='data-inner'>
                            <div className='b-first'>
                                <label>Flight Number, Train Station, Sea Port</label>
                                <input placeholder='Transport N°' className='full-w' onChange={(event) => setFormData({ ...formData, Pik_up_street: event.target.value })} value={formData.Pik_up_street}></input>
                            </div>
                        </div>
                    </div>
                    
                    {/* return details */}
                    {model ? 
                    <div className='data-line' id='return-data'>
                        <div className='data-inner'>
                            <div className='date'>
                                <label>Return date</label>
                                <input type='date' className='full-w return-date' onClick={restrictReturn} onChange={(event) => setFormData({ ...formData, Return_date: event.target.value })} value={formData.Return_date}/>
                            </div>
                            <div className='time'>
                                <label >Hour</label>
                                <select className='full-w' onClick={restrictReturn} onChange={(event) => setFormData({ ...formData, Return_hour: event.target.value })} value={formData.Return_hour}>
                                {returnhours.map((returnhour)=>{
                                    return(
                                        <option>{returnhour.value}</option>
                                    )
                                })}
                                </select>
                            </div>
                            <div className='time'>
                                <label>Min</label>
                                <select className='full-w' onChange={(event) => setFormData({ ...formData, Return_min: event.target.value })} value={formData.Return_min}>
                                {returnminutes.map((returnminute)=>{
                                    return(
                                        <option>{returnminute.value}</option>
                                    )
                                })}
                                </select>
                            </div>
                        </div>
                        <div className='data-inner'>
                            <div className='b-first'>
                                <label>Flight Number, Train Station, Sea Port</label>
                                <input placeholder='Transport N°' className='full-w' onChange={(event) => setFormData({ ...formData, Return_street: event.target.value })} value={formData.Return_street}></input>
                            </div>
                        </div>
                    </div>
                    : null}

                </div>
            </div>
    </div>
  )
}

export default Two
