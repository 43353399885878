import React from 'react'
import { ImCross } from "react-icons/im";



const Four = ({ formData, distance, duration }) => {


  let max = parseFloat(formData.no_of_passengers) + parseFloat(formData.Luggages) + (parseFloat(formData.Hand_bags) / 2);
  let maxNum, totalPrice; 
  let m_distance = (parseFloat(distance)*0.621371).toFixed(2)

  if(max <= 6){
    maxNum = 1;
    if(formData.Add_return === "No"){
      if(m_distance<=5){
        totalPrice = 25
      }else{
        totalPrice = 25 + (m_distance-5)*2;
      }
    }else{
      if(m_distance<=5){
        totalPrice = 25*2
      }else{
        totalPrice = (25 + (m_distance-5)*2)*2;
      }
    }
    
  }
  else if(max>6 && max<=8){
    maxNum = 2;
    if(formData.Add_return === "No"){
      if(m_distance<=5){
        totalPrice = 27
      }else{
        totalPrice = 27 + (m_distance-5)*2.2;
      }
    }else{
      if(m_distance<=5){
        totalPrice = 27*2
      }else{
        totalPrice = (27 + (m_distance-5)*2.2)*2;
      }
    }
    
  }
  else if(max>8 && max<=10){
    maxNum = 3;
    if(formData.Add_return === "No"){
      if(m_distance<=5){
        totalPrice = 30
      }else{
        totalPrice = 30 + (m_distance-5)*2.5;
      }
    }else{
      if(m_distance<=5){
        totalPrice = 30*2
      }else{
        totalPrice = (30 + (m_distance-5)*2.5)*2;
      }
    }
    
  }

  const carDetails = [
    {
      id: 1,
      carModel: "images/saloon.png",
      carName: "SALOON"
    },
    {
      id: 2,
      carModel: "images/estate.png",
      carName: "ESTATE"
    },
    {
      id: 3,
      carModel: "images/mpv.png",
      carName: "MPV"
    },
  ]

  return (
    <div className='calc-main-box'>
      <div className='calc-left-box'>
        <div className='calc-title'>Personal Details</div>
        <div className='calc-data'>
          <div className='text-box ntext-box'>
            <span className='colored-text ntext-box'>Name: </span>
            {formData.User_Name}
          </div>
          <div className='text-box ntext-box'>
            <span className='colored-text ntext-box'>Email: </span>
            {formData.User_Email}
          </div>
          <div className='text-box ntext-box'>
            <span className='colored-text ntext-box'>Number: </span>
            {formData.User_No}
          </div>
          <hr className='hr-line'/>
          <div className='stripe-method'><strong>Our Payment Method is Powered By Stripe</strong></div>
          <img src='images/payment.png' alt='' className='payment-img'/>
        </div>
      </div>
      <div className='options calc-right-box'>
        <div className='calc-title'>Your Journey</div>
        <div className='calc-data route-car'>
          <div className='car-details'>
            <div className='car-inner'>
              {carDetails.map((cardetail) => {
                if (cardetail.id === maxNum) {
                  return (
                    <img src={cardetail.carModel} alt='' className='car-icon' />
                  )
                }
              })}

              <div>
                <div>
                  {carDetails.map((cardetail) => {
                    if (cardetail.id === maxNum) {
                      return (
                        <strong>{cardetail.carName}</strong>
                      )
                    }
                  })}
                </div>
                <div className='luggage-detail'>
                  <span className='luggage-detail-inner'><img src='images/people.png' alt='' className='small-icon' /> <ImCross className='cross-icon' /><strong> {formData.no_of_passengers}</strong></span>
                  <span className='luggage-detail-inner'><img src='images/luggage.png' alt='' className='small-icon' /> <ImCross className='cross-icon' /><strong> {formData.Luggages}</strong></span>
                  <span className='luggage-detail-inner'><img src='images/bag.png' alt='' className='small-icon' /> <ImCross className='cross-icon' /><strong> {formData.Hand_bags}</strong></span>
                </div>
              </div>
            </div>
            <strong className='final-amount'><strong>&#163;</strong> {totalPrice}</strong>
          </div>
          <div className='journey-details'>

            <div className='journey-box'>
              <div className='text-box ntext-box'>
                <span className='colored-text ntext-box'>Pik Up: </span>
                {formData.Pik_up}
              </div>
              <div className='text-box ntext-box'>
                <span className='colored-text ntext-box'>Drop Off: </span>
                {formData.Drop_off}
              </div>
              <div className='text-box ntext-box'>
                <span className='colored-text ntext-box'>Distance / Time: </span>
                {distance} / {duration}
              </div>
            </div>

            <div className='journey-box jbox-right'>
              <div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Pik Up Date: </span>
                  {formData.Pik_up_date}
                </div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Pik Up Time: </span>
                  {formData.Pik_up_hour} : {formData.Pik_up_min}
                </div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Waiting Time: </span>
                  {formData.Wait_hour} : {formData.Wait_mint}
                </div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Return: </span>
                  {formData.Add_return}
                </div>
              </div>
              <div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Return Date: </span>
                  {formData.Return_date}
                </div>
                <div className='text-box ntext-box'>
                  <span className='colored-text ntext-box'>Return Time: </span>
                  {formData.Return_hour} : {formData.Return_min}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Four
