import React from 'react';
import './Cars.css';
import { Link } from 'react-router-dom';

const Cars = () => {

  return (
    <div className='container cars'>
      <h2 className='cras-heading'>Our Cabs Commit to Make Your Trips Unique</h2>
      <div className='cars-box'>
        <div className='image'><img src='images/cab.png' alt=''/></div>
        <div className='carbox-details'>
            <h3>Learn About Our Cab Company</h3>
            <p className='cars-detail'>At company name, we're proud to be Luton's well-known airport taxi service. Our reputation is built on professionalism, transparency, and guaranteed satisfaction. We’re capable of serving any travel transport needs, whether you're traveling alone, or with your family, or as part of a larger group.<br/><br/>With a management team of over twenty years travel expertise and a dedicated team of experienced drivers all professionally trained, fully vetted and CRB checked we promise to provide you the best service available catering for all your specific demands and requirements. we understand that quality company name service requires more than just a professional appearance. They know the roadways in and around Luton, and are able to easily transport customers to Gatwick, Stansted, Heathrow and London City airports on time. We also serve all other areas, so even if you don't require airport taxi service, we can fill your transport needs.</p>
            <Link to="/about-us"><button className='btn-one'>Know More</button></Link>
        </div>
      </div>
    </div>
  )
}

export default Cars
