import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container footer-detail'>

        <div className='company-details'>
          <img src='images/flogo.png' alt=''/>
          <p className='logo-detail'>Easy and quick cab booking for smooth rides. We have simple booking, different kinds of cars, and clear prices. Travel comfortably and confidently with us!</p>
        </div>

        <div className='quick-links'>
          <span className='footer-bold'>Quick Links</span>
          <ul className='nav-list footer-menu'>
            <li><Link className='nav-item footer-item' to='/'>Home</Link></li>
            <li><Link className='nav-item footer-item' to='/about-us'>About Us</Link></li>
            <li><Link className='nav-item footer-item' to='/contact-us'>Contact Us</Link></li>
            <li><Link className='nav-item footer-item' to='/terms-&-Conditions'>Terms & Conditions</Link></li>
            <li><Link className='nav-item footer-item' to='/privacy-policy'>Privacy Policy</Link></li>
          </ul>
        </div>

        <div className='contact-details'>
          <span className='footer-bold'>Contact Details</span>
          <ul className='nav-list footer-menu'>
            <li><BsFillTelephoneFill/> +447395342406</li>
            <li><MdEmail/> contact@swifttransfers.co.uk</li>
            <li><IoLocationSharp/> Norman Road Luton Bedfordshire LU31JN.</li>
          </ul>
        </div>

      </div>
      <div className='container copyright'>
        Swift Transfers | Copyright © 2023
      </div>
    </div>
  )
}

export default Footer
