import React from 'react';
import "./termsPages.css";

const TermsAndConditions = () => {
    return (
        <div className='container terms-conditions'>
            <h2>SERVICES</h2><br/>
            <p>When you have chosen to travel with The Luton Airport Taxis we will do everything we can to guarantee that your journey goes as smooth as possible. We have all of our drivers licenced, DBS checked and have significant knowledge in regards to driving locally and out of town. Furthermore, our drivers are individually taught in customer service to deliver exceptional standard of service always.</p>
            <p>Upon your arrival an experienced driver with extensive knowledge of the local area will receive you in the vehicle booked to the amount of commuters and the baggage requests specified by you.</p>
            <p>We offer meet and greet service where a driver will meet you or your guests. If there are any requests for the drivers please let us know.</p>
            <h3 className='sub-h'>Terms Conditions</h3><br/>
            <h3>Bookings</h3>
            <ol>
                <li>Before travelling you are required to give enough time for the trip to make sure you arrive on time. The Luton Airport Taxis cannot be held responsible for any delaysthat have been caused by your incapability to givesufficient time to reach your destination on time.</li>
                <br/>
                <li>Please be aware that a child, no matter the age of the child, will be regarded as a single passenger. You must specify the correct seat required for the baby.</li>
                <br/>
                <li><strong>Payment &amp; Prices</strong>
                    <ol>
                        <li className='sub-li'>The Luton Airport Taxis delivers a quote created on data that has been provided by you. All prices, unless otherwise stated, are exclusive of VAT and if this charge is applicable, this will be charged in addition. If any there are material changes to the stated journey, The LutonAirport Taxis reserves the right to alter the provided quote.</li>
                        <li className='sub-li'>The quote provided contains a 35 minute waiting time after the specified landing time and the charge of parking at the airport. Any waiting period in excess of this time will be charged at the standard The Luton Airport Taxis waiting rate. We will do our best to review flight details and amend our service accordingly if there have been delays but we are not obliged to do so.</li>
                        <li className='sub-li'>When you have accepting a quotation from us you will receive confirmation of the booking. Please take the time to check this confirmation and certify that it is 100% accurate. If you do not notify The Luton Airport Taxis, we will not be responsible for any interruptions or costs which arise due to this mistake.</li>
                        <li className='sub-li'>Payment for journeys with us can be made via cash or debt card.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>The Service</strong>
                    <ol>
                        <li className='sub-li'>You are accountable for the behaviour of all of the passengers who are on board for the journey. If the vehicle is soiled, damaged or negatively impacted on by the passenger, a charge may be applied to cover cleaning costs.</li>
                        <li className='sub-li'>You are not permitted to eat, drink or smoke in the car.</li>
                        <li className='sub-li'>All children in the car should be restrained in a manner that is appropriate to their height, weight and age.</li>
                        <li className='sub-li'>We are not allowed to carry more passengers than is stated by licencing or insurance.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>Cancellations</strong>
                    <ol>
                        <li className='sub-li' >If you have to cancel with your booking with The Luton Airport Taxis, you should contact us as soon as you possibly can. If you do not cancel a vehicle until after it has been dispatched, a charge will be incurred. This charge will be based on the time and distance covered by the driver to the point of cancellation.</li>
                        <li className='sub-li'>If you have booked through our online booking system and you cancel before the vehicle has been dispatched, a charge may be levied on the original payment.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>Liability</strong>
                    <ol>
                        <li className='sub-li'>Although The Luton Airport Taxis will do all we can to ensure you arrive on time at your destination, we are not liable for any losses incurred due to delays that are beyond our control. There are no circumstances where The Luton Airport Taxis will be liable for any losses incurred by you or your business.</li>
                        <li className='sub-li'>Your luggage is carried at your own risk.</li>
                        <li className='sub-li'>If the vehicle breaks down during your journey with The Luton Airport Taxis, we will do what we can to provide an alternative car to allow you to complete your journey as quickly as you possibly can.</li>
                        <li className='sub-li'>You will not indemnify The Luton Airport Taxis for any expenses, damages, costs or losses that arise from any omission or act from anyone in your party.</li>
                        <li className='sub-li'>No party involved is able to exclude or limit their liability for personal injury or death which ash been caused by negligence or fraudulent misrepresentation or wilful default, or for any other matter which is classed as unenforceable by all/any applicable laws.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>Termination</strong>
                    <ol>
                        <li className='sub-li'>We reserve the right to terminate or refuse a booking, with immediate effect, if any vehicle or driver is placed at risk, whether through abuse, violence or damage, from you or any other passenger. There will be no refunds provided for any journey which has been terminated during the hire.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>Miscellaneous</strong>
                    <ol>
                        <li className='sub-li'>The Luton Airport Taxis may subcontract their obligation as stated in this agreement. You will not assign, delegate or transfer any of the obligations or rights you hold, under this agreement.</li>
                        <li className='sub-li'>The Luton Airport Taxis may alter the terms and conditions at any time and can do so by making these changes to this page. Please be sure to review these terms and conditions on a regular basis to ensure that you are aware of the applicable changes. Any existing bookings will be undertaken at applicate rates which were in effect at the point of making a booking.</li>
                        <li className='sub-li'>We store, use and process information with respect to the guidelines of the Data Protection Act of 1988.</li>
                        <li className='sub-li'>This agreement represents the full agreement between The Luton Airport Taxis and yourself.</li>
                        <li className='sub-li'>There is nothing contained in these terms and conditions which are intended to confer any advantage or benefit to a third party. No third party maintains the right to enforce rights under this agreement unless agreement has been put in place in writing.</li>
                    </ol>
                </li>
                <br/>
                <li><strong>Disputes</strong>
                    <ol>
                        <li className='sub-li'>This agreement will be undertaken in accordance with English law with you and The Luton Airport Taxisagreeing to follow the exclusive jurisdiction of the courts with regards to any claim or dispute of matters connected with this agreement.</li>
                    </ol>
                </li>
            </ol>
        </div>
    )
}

export default TermsAndConditions
