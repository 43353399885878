import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoCloseSharp } from 'react-icons/io5';


const Header = () => {

    function Open(){
        let openMenu = document.getElementById('open-menu');
        openMenu.style.right = "0";
    }

    function Close(){
        let openMenu = document.getElementById('open-menu');
        openMenu.style.right = "-82vw";
    }

  return (
    <div className='container header'>
      <Link to='/'><img src='images/logo.png' alt=''/></Link>
      
      <GiHamburgerMenu className='open-menu' onClick={Open}/>

      <div className='mobile-menu' id='open-menu'>
        <IoCloseSharp className="close-menu" onClick={Close}/>
        <ul className='nav-list'>
            <li className='border'><Link className='nav-item' to='/' onClick={Close}>Home</Link></li>
            <li className='border'><Link className='nav-item' to='/about-us' onClick={Close}>About Us</Link></li>
            <li className='border'><Link className='nav-item' to='/contact-us' onClick={Close}>Contact Us</Link></li>
        </ul>
      </div>

      <Link to='tel:+447395342406' className='btn-one link num'>+447395342406</Link>
    </div>
  )
}

export default Header
