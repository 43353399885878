import React from 'react';
import './Cta.css';
import { Link } from 'react-router-dom';


const Cta = () => {
  return (
    <div className='container cta'>
        <div className='cta-detail'>
            <h2>Pre-Book Taxi Service with Us</h2>
            <p className='cta-text'>Swift Transfers offers one of the simplest pre-booking methods available. Three simple steps secure your travel method, price and travel date. You simply need to enter your pick-up or drop off location, choose the type of car you require for your travels, and then provide us with a few pieces of personal information. We'll guarantee your driver will be waiting for you where and when you choose, and we also guarantee the price quoted during your pre-booking.</p>
            <Link to='/contact-us'><button className='btn-one btn-two'>Contact Us</button></Link>
      </div>
      <div className='cta-img-box'>
        <img src='images/green car.png' alt=''/>
      </div>
    </div>
  )
}

export default Cta
