import './App.css';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import Footer from './Components/Footer';
import Header from './Components/Header';
import About from './Pages/about/About';
import Home from './Pages/home/Home';
import Contact from './Pages/contact/Contact';
import Scroll from './Components/Scroll';
import Thankyou from './Pages/stripePages/Thankyou';
import Error from './Pages/stripePages/Error';
import TermsAndConditions from './Pages/termsPages/TermsAndConditions';
import PrivacyPolicy from './Pages/termsPages/PrivacyPolicy';

const Layout = ()=>{
  return(
      <>
      <Scroll />
      <Header />
      <Outlet />
      <Footer />
      </>
  )
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
          path: "/",
          element: <Home />
      },
      {
          path: "/about-us",
          element: <About />
      },
      {
        path: "/contact-us",
        element: <Contact />
      },
      {
        path: "/thank-you",
        element: <Thankyou />
      },
      {
        path: "/error",
        element: <Error />
      },
      {
        path: "/terms-&-Conditions",
        element: <TermsAndConditions />
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />
      }
    ]
  }
]);


function App() {
  return (
    <RouterProvider router={router}/>
  );
}
export default App;
