import React, { useRef, useState } from 'react';
import './Calc.css';
import One from './Steps/One';
import Two from './Steps/Two';
import Three from './Steps/Three';
import Four from './Steps/Four';
import { useJsApiLoader, Autocomplete, GoogleMap, DirectionsRenderer, Marker } from "@react-google-maps/api";
// const google = window.google;


const Calc = () => {
  const [libraries] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [page, setPage] = useState(0);
  const formTitles = ["step one", "step two", "step three", "step four"];


  const [formData, setFormData] = useState({
    Pik_up: "",
    Drop_off: "",
    Add_return: "No",
    no_of_passengers: "1",
    Luggages: "0",
    Hand_bags: "0",
    Wait_hour: "0",
    Wait_mint: "0",
    Pik_up_date: "",
    Pik_up_hour: "0",
    Pik_up_min: "0",
    Pik_up_street: "",
    Return_date: "Enter return date",
    Return_hour: "00",
    Return_min: "00",
    Return_street: "",
    User_Name: "",
    User_No: "",
    User_Email: "",
    Total_Distance: "0",
    Total_Duration: "0",
    Final_Price: "00",
  });

  // google map items

  const [directionResponse, setDirectionResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')

  /**@type React.MutableRefobject<HTMLInputElement> */
  const originRef = useRef()

  /**@type React.MutableRefobject<HTMLInputElement> */
  const destinationRef = useRef()

  // map loding
  if (!isLoaded) {
    return (
      <div className='container'>
        <div className='cal-pre-loader'>
          <span>Loading...</span>
        </div>
      </div>
    )
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionService = new google.maps.DirectionsService()
    const results = await directionService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })

    setDirectionResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)

    console.log(originRef.current.value)
    console.log(destinationRef.current.value)
    // setLocationData({...locationData, Pik_up: originRef.current.value});
    setFormData({ ...formData, Drop_off: destinationRef.current.value });
  }

  // google pick-up and drop-off locations 
  function userLocation() {
    const center = { lat: 51.87542739308963, lng: -0.3724768414335228 };
    return (
      <>
        <div className='journey calc-left-box'>
          <div className='calc-title'>Your Journey</div>
          <div className='calc-data'>
            <label>Pik-Up</label>
            <Autocomplete>
              <input id='pickUp' className='full-w'
                placeholder='Address / Hotel'
                ref={originRef}
                value={formData.Pik_up}
                onBlur={(event) => setFormData({ ...formData, Pik_up: event.target.value })}
                onChange={(event) => setFormData({ ...formData, Pik_up: event.target.value })}
              />
            </Autocomplete>
            <label>Drop-Off</label>
            <Autocomplete onPlaceChanged={calculateRoute}>
              <input className='full-w'
                placeholder='Address / Hotel'
                ref={destinationRef}
                value={formData.Drop_off}
                onBlur={(event) => setFormData({ ...formData, Drop_off: event.target.value })}
                onChange={(event) => setFormData({ ...formData, Drop_off: event.target.value })} />
            </Autocomplete>
          </div>
        </div>

        <div className='options calc-right-box'>
          <div className='calc-title'>Map</div>
          <div className='calc-data map-box'>
            <GoogleMap
              center={center}
              zoom={10}
              mapContainerStyle={{ width: '100%', height: '100%' }}>
              <Marker position={center} />
              {directionResponse && <DirectionsRenderer directions={directionResponse} />}
            </GoogleMap>
          </div>
        </div>
      </>
    )
  }


  const pageDisplay = () => {
    if (page === 0) {
      return <One userLocation={userLocation()}/>
    } else if (page === 1) {
      return <Two formData={formData} setFormData={setFormData} distance = {distance} duration={duration}/>
    } else if (page === 2) {
      return <Three formData={formData} setFormData={setFormData} distance = {distance} duration={duration}/>
    } else {
      return <Four formData={formData} distance = {distance} duration={duration}/>
    }
  }




  return (
    <div className='container' id='calc'>
      <div className='calculator'>

        <div className='p-bar'>
          <div className='calc-step' style={{ backgroundColor: page === 0 ? "white" : "#333333", color: page === 0 ? "#333333" : "white" }}><strong>Step 1</strong> <span>Your Journey</span></div>
          <div className='calc-step' style={{ backgroundColor: page === 1 ? "white" : "#333333", color: page === 1 ? "#333333" : "white" }}><strong>Step 2</strong> <span>Quotation</span></div>
          <div className='calc-step' style={{ backgroundColor: page === 2 ? "white" : "#333333", color: page === 2 ? "#333333" : "white" }}><strong>Step 3</strong> <span>Personal Details</span></div>
          <div className='calc-step' style={{ backgroundColor: page === 3 ? "white" : "#333333", color: page === 3 ? "#333333" : "white" }}><strong>Step 4</strong> <span>Payment</span></div>
        </div>

        <div className='clac-form'>
          <div className='calc-body'>{pageDisplay()}</div>

          <div className='calc-footer'>
            <button className='btn-two'
              disabled={page === 0}
              onClick={() => {
                setPage((currentPage) => currentPage - 1);
              }}>Prev
            </button>
            <form action="https://server.swifttransfers.co.uk/register">
            <button className='btn-two'

              onClick={async(e) => {
                e.preventDefault();
                if(page === formTitles.length - 1){
                  console.log(formData.Drop_off)
                  const res = await fetch("https://server.swifttransfers.co.uk/register", {
                    method: "POST",
                    headers:{
                      "Content-Type": "application/json"
                    },body:JSON.stringify({
                      formData
                    })
                  });
                  const body = await res.json()
                  window.location.href = body.url
                  console.log(res)
                }else{
                  setPage((currentPage) => currentPage + 1);
                }
              }}>
                {page === formTitles.length - 1 ? "Proceed to pay" : "Next"}
            </button></form>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Calc
