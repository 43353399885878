import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div className='container hero back'>
      <h1>Book a <span className='color'>City Cab</span> to Your <br/>Destination in Town</h1>
    </div>
  )
}

export default Hero
