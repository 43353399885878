import React from 'react';
import Hero from './components/Hero/Hero';
import Calc from './components/Calculator/Calc';
import Cars from './components/Cars/Cars';
import Classes from './components/Classes/Classes';
import Cta from './components/Cta/Cta';
import City from './components/City/City';
import Why from './components/Why/Why';

const Home = () => {
  return (
    <>
      <Hero/>
      <Calc/>
      <Cars/>
      <Classes/>
      <Cta/>
      <City/>
      <Why/>
    </>
  )
}

export default Home
